import { createBrowserHistory } from 'history';

const scripts = {
  reddit: {
    __html: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_e4ba8g8u', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');`,
  },
  equalWeb: (locale) => ({
    __html: `window.interdeal = { "sitekey": "dd4f94788601d746ef6b03e10c0ceafd", "Position": "Left", "Menulang": '${locale}', "domains": { "js": "https://cdn.equalweb.com/", "acc": "https://access.equalweb.com/" }, "btnStyle": { "vPosition": [ "80%", null ], "scale": [ "0.8", "0.8" ], "color": { "main": "#1876c9", "second": "" }, "icon": { "type": 7, "shape": "semicircle", "outline": false } } }; (function(doc, head, body){ var coreCall = doc.createElement('script'); coreCall.setAttribute('id', 'equalweb-script'); coreCall.src = interdeal.domains.js + 'core/4.5.6/accessibility.js'; coreCall.defer = true; coreCall.integrity = 'sha512-rotepgID7jK/CgENxVXOfMA1+62xwcnONU14mNlHVkywU+O9+uN5JqPHxBCJpGJhc3eUFYdtdjRaTdf6uffOyg=='; coreCall.crossOrigin = 'anonymous'; coreCall.setAttribute('data-cfasync', true ); body? body.appendChild(coreCall) : head.appendChild(coreCall); })(document, document.head, document.body);`,
  }),
  tiktok: {
    __html: `!function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
    )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
  
      ttq.load('BTGIL6BQ55EMJL0L2V00');
      ttq.page();
    }(window, document, 'ttq');`,
  },
  tagmanager: {
    __html: `(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-KGDFZD8')`,
  },
  intercom: {
    __html: `
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.GATSBY_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `,
  },
  spotify: {
    __html: `
    (function(w, d){
      var id='spdt-capture', n='script';
      if (!d.getElementById(id)) {
        w.spdt =
          w.spdt ||
          function() {
            (w.spdt.q = w.spdt.q || []).push(arguments);
          };
        var e = d.createElement(n); e.id = id; e.async=1;
        e.src = 'https://pixel.byspotify.com/ping.min.js';
        var s = d.getElementsByTagName(n)[0];
        s.parentNode.insertBefore(e, s);
      }
      w.spdt('conf', { key: '01160d0d14ca476895c1bf31a0473ada' });
      w.spdt('view');
    })(window, document);`,
  },
  amazonAds: {
    __html: `
      !(function (w, d, s, t, a) {
        if (w.amzn) return;
        w.amzn = a = function () {
          w.amzn.q.push([arguments, new Date().getTime()]);
        };
        a.q = [];
        a.version = '0.0';
        s = d.createElement('script');
        s.src = 'https://c.amazon-adsystem.com/aat/amzn.js';
        s.id = 'amzn-pixel';
        s.async = true;
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
      })(window, document);
      amzn('setRegion', 'NA');
      amzn('addTag', 'd53eac20-4c8e-41f0-a062-064a2f2fa891');
      amzn('trackEvent', 'PageView');
    `,
  },
};

export default scripts;

export const appendThirdPartyScriptsSync = () => {
  appendScriptTagManager();
  appendScript(scripts.reddit.__html);
  appendScript(scripts.tiktok.__html);
  appendScript(scripts.spotify.__html);
  appendScriptSwitchBoost();
  appendScriptAmazonAds();
};

export const appendThirdPartyScriptsAsync = () => {
  initSentry();

  window.removeEventListener('keydown', appendThirdPartyScriptsAsync);
  window.removeEventListener('pointerover', appendThirdPartyScriptsAsync);
};

const appendScriptTagManager = () => {
  const elementNoscript = document.createElement('noscript');
  const elementIframe = document.createElement('iframe');
  elementIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-KGDFZD8';
  elementIframe.height = '0';
  elementIframe.width = '0';
  elementIframe.style = 'display:none;visibility:hidden';
  elementNoscript.appendChild(elementIframe);

  appendScript(scripts.tagmanager.__html);
  document.body.appendChild(elementNoscript);
};

const appendScriptSwitchBoost = () => {
  const scriptElement = document.createElement('script');
  scriptElement.src = 'https://pixel.switchgrowth.com/pixel.js?id=AxuB4szNGyBhNlWZ';
  document.head.appendChild(scriptElement);
};

const appendScriptAmazonAds = () => {
  const elementNoscript = document.createElement('noscript');
  const elementImg = document.createElement('img');
  elementImg.src = 'https://s.amazon-adsystem.com/iu3?pid=d53eac20-4c8e-41f0-a062-064a2f2fa891&amp;event=PageView';
  elementImg.width = 1;
  elementImg.height = 1;
  elementImg.border = '0';
  elementNoscript.appendChild(elementImg);

  appendScript(scripts.amazonAds.__html);
  document.body.appendChild(elementNoscript);
};

export const appendScript = (scriptInnerHTML) => {
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = scriptInnerHTML;
  document.head.appendChild(scriptElement);
};

const appendScriptEqualWeb = (locale) => {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('id', 'equalweb-load-script');
  scriptElement.innerHTML = scripts.equalWeb(locale).__html;
  document.head.appendChild(scriptElement);
};

export const handleScriptEqualWeb = ({ pathname, prevPathname }) => {
  if (!prevPathname) {
    // No previous path, append english or french depending on locale
    appendScriptEqualWeb(!pathname.includes('/fr') ? 'EN' : 'FR');
  } else {
    const isEnToFr = !prevPathname?.includes('fr') && pathname.includes('fr');
    const isFrToEn = prevPathname?.includes('fr') && !pathname.includes('fr');

    // If locale is switched, remove equalweb
    if (isEnToFr || isFrToEn) {
      delete (window as any).interdeal;
      document?.getElementById('equalweb-script')?.remove();
      document?.getElementById('equalweb-load-script')?.remove();
    }

    // After previous language removed, append current language
    if (isEnToFr) {
      appendScriptEqualWeb('FR');
    } else if (isFrToEn) {
      appendScriptEqualWeb('EN');
    }
  }
};

const initSentry = async () => {
  if (process.env.NODE_ENV === 'production') {
    const Sentry = await import('@sentry/gatsby');
    const history = createBrowserHistory();

    Sentry.init({
      dsn: process.env.GATSBY_SENTRY_KEY,
      integrations: [
        new Sentry.BrowserTracing({
          tracingOrigins: ['localhost', 'https://www.koho.ca/', /^\//],
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      environment: process.env.GATSBY_SENTRY_ENVIRONMENT,
      release: process.env.GATSBY_SENTRY_PROJECT,
      tracesSampleRate: 0.05,
      ignoreErrors: [
        'NetworkError:  A network error occurred.',
        /TypeError/,
        `undefined is not an object (evaluating 'a.O')`,
      ],
      denyUrls: [/googleads\.g\.doubleclick\.net/i],
    });
  }
};
