const pageVariants = {
  home: {
    sem: [
      'best-prepaid-card-canada',
      'budget-calculator',
      'budget-planner',
      'cash-app',
      'debit-card-account',
      'easiest-credit-card',
      'expense-tracker',
      'venmo',
      'virtual-credit-card',
    ],
    paid: ['google', 'facebook', 'tiktok'],
  },
  subscribe: [
    {
      variant: 'premiumPlus',
      includeFrench: true,
      path: '/subscribe-premium-plus/',
    },
    { variant: 'crypto', includeFrench: false, path: '/subscribe-crypto/' },
    {
      includeFrench: true,
      variant: `freemium-groceries`,
      path: `/subscribe/freemium/groceries/`,
    },
    {
      includeFrench: true,
      variant: `freemium-groceries+transportation`,
      path: `/subscribe/freemium/groceries+transportation/`,
    },
    {
      includeFrench: true,
      variant: `freemium-groceries+bills+services`,
      path: `/subscribe/freemium/groceries+bills+services/`,
    },
    {
      includeFrench: false,
      variant: `paid-ep-active+cover-active`,
      path: `/subscribe/cover-migration/variant-a/`,
    },
    {
      includeFrench: false,
      variant: `free-ep-active+cover-active`,
      path: `/subscribe/cover-migration/variant-b/`,
    },
    {
      includeFrench: false,
      variant: `free-ep-active+cover-eligible`,
      path: `/subscribe/cover-migration/variant-c/`,
    },
    {
      includeFrench: false,
      variant: `paid-ep-active+cover-eligible`,
      path: `/subscribe/cover-migration/variant-d/`,
    },
  ],
  subscribeAlt: [
    { includeFrench: true, path: '/premium-plus-14/', variant: 'premiumPlus' },
    { includeFrench: true, path: '/premium-plus-19/', variant: 'premiumPlus' },
    { includeFrench: false, path: '/subscribe/easy/', variant: 'easy' },
    { includeFrench: false, path: '/subscribe/easy-01/', variant: 'easy01' },
    {
      includeFrench: false,
      path: '/subscribe/easy-opt-out/',
      variant: 'easyOptOut',
    },
  ],
};

const pathnamesBlocklistLocaleFr = [
  '/travel/',
  '/holidays/',
  '/learn/glossary/',
  '/premium/foodie/',
  ...pageVariants.home.sem.map((variant) => `/sem/${variant}/`),
  ...pageVariants.home.paid.map((variant) => `/paid/${variant}/`),
  ...pageVariants.subscribe.filter((variant) => !variant.includeFrench).map((variant) => variant.path),
  ...pageVariants.subscribeAlt.filter((variant) => !variant.includeFrench).map((variant) => variant.path),
];

const pathnamesBlocklistLocaleFrShort = pathnamesBlocklistLocaleFr.map((path) => path.slice(0, path.length - 1));

const urlBlocklists = {
  locale: {
    fr: [...pathnamesBlocklistLocaleFr, ...pathnamesBlocklistLocaleFrShort],
  },
};

const whitelistQueryParamsHash = {
  email: 'email',
  referral: 'referral',
};

const whitelistQueryParamsSearch = {
  adset_id: 'adset_id',
  af_ad_id: 'af_ad_id',
  af_adset: 'af_adset',
  af_adset_id: 'af_adset',
  af_c_id: 'af_c_id',
  af_channel: 'af_channel',
  af_click_lookback: 'af_click_lookback',
  af_sub1: 'af_sub1',
  af_sub2: 'af_sub2',
  af_sub3: 'af_sub3',
  af_sub4: 'af_sub4',
  c: 'c',
  campaign_id: 'campaign_id',
  clickid: 'clickid',
  deep_link_value: 'deep_link_value',
  GBRAID: 'GBRAID',
  gclid: 'gclid',
  irclickid: 'irclickid',
  irgwc: 'irgwc',
  pid: 'pid',
  ranEAID: 'ranEAID',
  ranMID: 'ranMID',
  ranSiteID: 'ranSiteID',
  rdt_cid: 'rdt_cid',
  shortlink: 'shortlink',
  ttclid: 'ttclid',
  utm_ad_id: 'utm_ad_id',
  utm_adset_id: 'utm_adset_id',
  utm_ad_name: 'utm_ad_name',
  utm_adset_name: 'utm_adset_name',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  utm_medium: 'utm_medium',
  utm_source: 'utm_source',
  WBRAID: 'WBRAID',
};

const whitelistQueryParams = {
  hash: whitelistQueryParamsHash,
  search: whitelistQueryParamsSearch,
};

const redirects = [
  {
    from: '/wp-content/uploads/*',
    to: 'https://assets.koho.ca/legacy-wp-assets/:splat/',
    status: 200,
  },
  {
    from: '/author/',
    to: '/learn/',
    status: 307, // The requested resource resides temporarily under a different URI.
  },
  {
    from: '/black-friday/',
    to: '/',
    status: 301,
  },
  {
    from: '/holidays/',
    to: '/',
    status: 301,
  },
  {
    from: '/quebec-waitlist/',
    to: '/',
    status: 301,
  },
  {
    from: '/nofees/',
    to: '/legal/',
    status: 301,
  },
  {
    from: '/legal#KOHOSaveInterestRates/',
    to: '/legal/#CardHolderAgreement',
    status: 301,
  },
  {
    from: '/legal/#KOHOSaveInterestRates/',
    to: '/legal/#CardHolderAgreement',
    status: 301,
  },
  {
    from: '/card-holders-agreement/',
    to: '/legal/#CardHolderAgreement',
    status: 301,
  },
  {
    from: '/card-holders-agreement-2/',
    to: '/legal/#CardHolderAgreement',
    status: 301,
  },
  {
    from: '/cardholder-agreement/',
    to: '/legal/#CardHolderAgreement',
    status: 301,
  },
  {
    from: '/accord-des-detenteurs-de-cartes/',
    to: '/legal/',
    status: 301,
  },
  {
    from: '/mobile-wallet-terms-of-use/',
    to: '/legal/#MobileWalletTermsOfUse',
    status: 301,
  },
  {
    from: '/conditions-generales-dutilisation/',
    to: '/legal/',
    status: 301,
  },
  {
    from: '/privacy-policy/',
    to: '/legal/#PrivacyPolicy',
    status: 301,
  },
  {
    from: '/terms-of-use/',
    to: '/legal/#KOHOTermsOfUse',
    status: 301,
  },
  {
    from: '/politique-de-confidentialite/',
    to: '/fr/legal/#PrivacyPolicy',
    status: 301,
  },
  {
    from: '/team/',
    to: '/careers/',
    status: 301,
  },
  {
    from: '/work-here/',
    to: '/careers/',
    status: 301,
  },
  {
    from: '/careers-2/',
    to: '/careers/',
    status: 301,
  },
  {
    from: '/power-ups/',
    to: 'https://help.koho.ca/features/what-are-powerups/',
    status: 301,
  },
  {
    from: '/support/',
    to: '/contact/',
    status: 301,
  },
  {
    from: '/contact-us/',
    to: '/contact/',
    status: 301,
  },
  {
    from: '/careers-new/',
    to: '/careers/',
    status: 301,
  },
  {
    from: '/category/blog/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/refinery/',
    to: '/',
    status: 301,
  },
  {
    from: '/metal-join-waitlist/',
    to: '/',
    status: 301,
  },
  {
    from: '/metal-check-position/',
    to: '/',
    status: 301,
  },
  {
    from: '/metal/',
    to: '/',
    status: 301,
  },
  {
    from: '/nba/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/nba/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/learn/prepaid-visa-myths/',
    to: '/learn/prepaid-mastercard-myths/',
    status: 301,
  },
  {
    from: '/learn/prepaid-visa-card-koho/',
    to: '/learn/10-reasons-why-you-should-get-a-prepaid-mastercard/',
    status: 301,
  },
  {
    from: '/learn/prepaid-mastercard-koho/',
    status: 301,
    to: '/learn/10-reasons-why-you-should-get-a-prepaid-mastercard/',
  },
  {
    from: '/fr/learn/prepaid-visa-card-koho/',
    to: '/fr/learn/10-reasons-why-you-should-get-a-prepaid-mastercard/',
    status: 301,
  },
  {
    from: 'fr/learn/prepaid-mastercard-koho/',
    status: 301,
    to: '/fr/learn/10-reasons-why-you-should-get-a-prepaid-mastercard/',
  },
  {
    from: '/learn/premium-prepaid-visa-account/',
    to: '/learn/premium-prepaid-mastercard-account/',
    status: 301,
  },
  {
    from: '/fr/learn/premium-prepaid-visa-account/',
    to: '/fr/learn/premium-prepaid-mastercard-account/',
    status: 301,
  },
  // Instant Pay redirects
  {
    from: '/instant-pay-partner/*',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/instant-pay-partner/*',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/osl/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/osl/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/ameego/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/ameego/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/westmont/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/westmont/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/mty_food/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/mty_food/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/seasons_retirement/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/seasons_retirement/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/dryco/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/dryco/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/mty/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/mty/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/hai_di_lao/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/hai_di_lao/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/bento_sushi/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/bento_sushi/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/tango/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/tango/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/tiger_calcium/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/tiger_calcium/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/instant-pay-partner/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/instant-pay-partner/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/instant-pay/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/instant-pay/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/instant-pay-employee/',
    to: '/',
    status: 301,
  },
  {
    from: '/partners/',
    status: 301,
    to: '/',
  },
  {
    from: '/fr/partners/',
    status: 301,
    to: '/fr/',
  },
  {
    from: '/easy/*',
    to: '/',
    force: true,
    isPermanent: true,
    status: 301,
  },
  {
    from: '/fr/easy/*',
    to: '/fr/',
    force: true,
    isPermanent: true,
    status: 301,
  },
  {
    from: '/v1/*',
    to: '/:splat',
    force: true,
    isPermanent: true,
    status: 301,
  },
  {
    from: '/fr/v1/*',
    to: '/fr/:splat',
    force: true,
    isPermanent: true,
    status: 301,
  },
  {
    from: '/fr/instant-pay-employee/',
    to: '/fr/',
    status: 301,
  },
  {
    from: '/learn/is-instant-pay-secure/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/instant-pay-is-now-available-on-adp-marketplace/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/how-does-instant-pay-work/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/will-instant-pay-work-with-my-bank/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/why-should-employers-consider-instant-pay/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/why-instant-pay-is-becoming-more-popular-during-inflation/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/earned-wage-access-pros-and-cons/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/why-are-employers-signing-up-for-instant-pay/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/what-do-employers-have-to-say-about-instant-pay/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/learn/how-fast-is-instant-pay/',
    to: '/learn/',
    status: 301,
  },
  {
    from: '/early-payroll/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/early-payroll/',
    to: '/',
    status: 301,
  },
  {
    from: '/premium/',
    to: '/extra/',
    status: 301,
  },
  {
    from: '/fr/premium/',
    to: '/fr/extra/',
    status: 301,
  },
  {
    from: '/premium',
    to: '/extra/',
    status: 301,
  },
  {
    from: '/fr/premium',
    to: '/fr/extra/',
    status: 301,
  },
  {
    from: '/government-benefits-eligibility/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/government-benefits-eligibility/',
    to: '/',
    status: 301,
  },
  {
    from: '/government-benefits-secondary-eligibility/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/government-benefits-secondary-eligibility/',
    to: '/',
    status: 301,
  },
  {
    from: '/government-benefits-quiz/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/government-benefits-quiz/',
    to: '/',
    status: 301,
  },
  {
    from: '/government-benefits-how-to-apply/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/government-benefits-how-to-apply/',
    to: '/',
    status: 301,
  },
  {
    from: '/government-benefits-finder/',
    to: '/',
    status: 301,
  },
  {
    from: '/fr/government-benefits-finder/',
    to: '/',
    status: 301,
  },
  {
    from: '/learn/increase-your-credit-score/',
    to: '/learn/how-to-improve-your-credit-score/',
    status: 301,
  },
  {
    from: '/fr/learn/increase-your-credit-score/',
    to: '/fr/learn/how-to-improve-your-credit-score/',
    status: 301,
  },
  {
    from: '/gamer/',
    to: '/learn/can-you-get-cash-back-on-gaming-purchases/',
    status: 301,
  },
  {
    from: '/learn/credit-score-apps/',
    status: 301,
    to: '/credit-building/credit-score-apps/',
  },
  {
    from: '/learn/predatory-lending/',
    status: 301,
    to: '/personal-finance/predatory-lending/',
  },
  {
    from: '/learn/credit-score-applying-for-credit-card/',
    status: 301,
    to: '/credit-building/credit-score-applying-for-credit-card/',
  },
  {
    from: '/learn/how-to-understand-your-credit-report/',
    status: 301,
    to: '/credit-building/how-to-understand-your-credit-report/',
  },
  {
    from: '/learn/credit-card-annual-fees/',
    status: 301,
    to: '/credit-cards/credit-card-annual-fees/',
  },
  {
    from: '/learn/bank-account-with-bad-credit/',
    status: 301,
    to: '/banking/bank-account-with-bad-credit/',
  },
  {
    from: '/learn/when-credit-card-companies-report/',
    status: 301,
    to: '/credit-building/when-credit-card-companies-report/',
  },
  {
    from: '/learn/gic-early-withdrawl/',
    status: 301,
    to: '/save/gic-early-withdrawl/',
  },
  {
    from: '/learn/secured-credit-card-ontario/',
    status: 301,
    to: '/credit-cards/secured-credit-card-ontario/',
  },
  {
    from: '/learn/loan-with-bad-credit/',
    status: 301,
    to: '/credit-building/loan-with-bad-credit/',
  },
  {
    from: '/learn/how-does-a-gic-work/',
    status: 301,
    to: '/save/how-does-a-gic-work/',
  },
  {
    from: '/learn/credit-report-authorization/',
    status: 301,
    to: '/credit-building/credit-report-authorization/',
  },
  {
    from: '/learn/non-redeemable-gic/',
    status: 301,
    to: '/save/non-redeemable-gic/',
  },
  {
    from: '/learn/secured-credit-card-manitoba/',
    status: 301,
    to: '/credit-cards/secured-credit-card-manitoba/',
  },
  {
    from: '/learn/secured-credit-card-alberta/',
    status: 301,
    to: '/credit-cards/secured-credit-card-alberta/',
  },
  {
    from: '/learn/secured-credit-card-british-columbia/',
    status: 301,
    to: '/credit-cards/secured-credit-card-british-columbia/',
  },
  {
    from: '/learn/closed-accounts-on-credit-report/',
    status: 301,
    to: '/credit-building/closed-accounts-on-credit-report/',
  },
  {
    from: '/learn/how-to-build-credit-from-scratch/',
    status: 301,
    to: '/credit-building/how-to-build-credit-from-scratch/',
  },
  {
    from: '/learn/transfer-money-between-bank/',
    status: 301,
    to: '/banking/transfer-money-between-bank/',
  },
  {
    from: '/learn/mortgage-with-bad-credit/',
    status: 301,
    to: '/credit-building/mortgage-with-bad-credit/',
  },
  {
    from: '/learn/790-credit-score/',
    status: 301,
    to: '/credit-building/790-credit-score/',
  },
  {
    from: '/learn/760-credit-score/',
    status: 301,
    to: '/credit-building/760-credit-score/',
  },
  {
    from: '/learn/740-credit-score/',
    status: 301,
    to: '/credit-building/740-credit-score/',
  },
  {
    from: '/learn/734-credit-score-range/',
    status: 301,
    to: '/credit-building/734-credit-score-range/',
  },
  {
    from: '/learn/724-credit-score-range/',
    status: 301,
    to: '/credit-building/724-credit-score-range/',
  },
  {
    from: '/learn/720-credit-score-range/',
    status: 301,
    to: '/credit-building/720-credit-score-range/',
  },
  {
    from: '/learn/704-credit-score-range/',
    status: 301,
    to: '/credit-building/704-credit-score-range/',
  },
  {
    from: '/learn/700-credit-score-range/',
    status: 301,
    to: '/credit-building/700-credit-score-range/',
  },
  {
    from: '/learn/696-credit-score-range/',
    status: 301,
    to: '/credit-building/696-credit-score-range/',
  },
  {
    from: '/learn/690-credit-score-range/',
    status: 301,
    to: '/credit-building/690-credit-score-range/',
  },
  {
    from: '/learn/670-credit-score-range/',
    status: 301,
    to: '/credit-building/670-credit-score-range/',
  },
  {
    from: '/learn/663-credit-score-range/',
    status: 301,
    to: '/credit-building/663-credit-score-range/',
  },
  {
    from: '/learn/660-credit-score-range/',
    status: 301,
    to: '/credit-building/660-credit-score-range/',
  },
  {
    from: '/learn/650-credit-score-range/',
    status: 301,
    to: '/credit-building/650-credit-score-range/',
  },
  {
    from: '/learn/613-credit-score-range/',
    status: 301,
    to: '/credit-building/613-credit-score-range/',
  },
  {
    from: '/learn/610-credit-score-range/',
    status: 301,
    to: '/credit-building/610-credit-score-range/',
  },
  {
    from: '/learn/600-credit-score-range/',
    status: 301,
    to: '/credit-building/600-credit-score-range/',
  },
  {
    from: '/learn/credit-card-bill-credit-score/',
    status: 301,
    to: '/credit-building/credit-card-bill-credit-score/',
  },
  {
    from: '/learn/check-credit-score/',
    status: 301,
    to: '/credit-building/check-credit-score/',
  },
  {
    from: '/learn/credit-report-for-landlord/',
    status: 301,
    to: '/credit-building/credit-report-for-landlord/',
  },
  {
    from: '/learn/credit-limit-effect-on-credit-score/',
    status: 301,
    to: '/credit-building/credit-limit-effect-on-credit-score/',
  },
  {
    from: '/learn/refund-timeline/',
    status: 301,
    to: '/credit-cards/refund-timeline/',
  },
  {
    from: '/learn/credit-monitoring/',
    status: 301,
    to: '/credit-building/credit-monitoring/',
  },
  {
    from: '/learn/soft-credit-check-affect-credit-score/',
    status: 301,
    to: '/credit-building/soft-credit-check-affect-credit-score/',
  },
  {
    from: '/learn/remove-negative-things-credit-report/',
    status: 301,
    to: '/credit-building/remove-negative-things-credit-report/',
  },
  {
    from: '/learn/change-billing-address-on-credit-card/',
    status: 301,
    to: '/credit-cards/change-billing-address-on-credit-card/',
  },
  {
    from: '/learn/credit-disputes-affect-credit-score/',
    status: 301,
    to: '/credit-building/credit-disputes-affect-credit-score/',
  },
  {
    from: '/learn/find-billing-address/',
    status: 301,
    to: '/credit-cards/find-billing-address/',
  },
  {
    from: '/learn/does-credit-score-drop-when-you-check/',
    status: 301,
    to: '/credit-building/does-credit-score-drop-when-you-check/',
  },
  {
    from: '/learn/credit-score-ranges/',
    status: 301,
    to: '/credit-building/credit-score-ranges/',
  },
  {
    from: '/learn/credit-card-interest-monthly-or-yearly/',
    status: 301,
    to: '/credit-cards/credit-card-interest-monthly-or-yearly/',
  },
  {
    from: '/learn/good-credit-score-range/',
    status: 301,
    to: '/credit-building/good-credit-score-range/',
  },
  {
    from: '/learn/poor-credit-score/',
    status: 301,
    to: '/credit-building/poor-credit-score/',
  },
  {
    from: '/learn/can-you-get-interest-on-a-chequing-account/',
    status: 301,
    to: '/banking/can-you-get-interest-on-a-chequing-account/',
  },
  {
    from: '/learn/grocery-store-credit-card/',
    status: 301,
    to: '/credit-cards/grocery-store-credit-card/',
  },
  {
    from: '/learn/secured-credit-card/',
    status: 301,
    to: '/credit-cards/secured-credit-card/',
  },
  {
    from: '/learn/credit-card-for-groceries/',
    status: 301,
    to: '/credit-cards/credit-card-for-groceries/',
  },
  {
    from: '/learn/credit-card-balance-effect-on-credit-score/',
    status: 301,
    to: '/credit-building/credit-card-balance-effect-on-credit-score/',
  },
  {
    from: '/learn/charge-card-vs-credit-card/',
    status: 301,
    to: '/credit-cards/charge-card-vs-credit-card/',
  },
  {
    from: '/learn/average-credit-card-debt/',
    status: 301,
    to: '/credit-cards/average-credit-card-debt/',
  },
  {
    from: '/learn/negative-balance-on-credit-card/',
    status: 301,
    to: '/credit-cards/negative-balance-on-credit-card/',
  },
  {
    from: '/learn/costo-payment-methods/',
    status: 301,
    to: '/credit-cards/costo-payment-methods/',
  },
  {
    from: '/learn/credit-card-interest/',
    status: 301,
    to: '/credit-cards/credit-card-interest/',
  },
  {
    from: '/learn/balance-transfer-credit-card/',
    status: 301,
    to: '/credit-cards/balance-transfer-credit-card/',
  },
  {
    from: '/learn/credit-card-declined/',
    status: 301,
    to: '/credit-cards/credit-card-declined/',
  },
  {
    from: '/learn/going-over-credit-limit/',
    status: 301,
    to: '/credit-cards/going-over-credit-limit/',
  },
  {
    from: '/learn/negotiate-credit-card-interest/',
    status: 301,
    to: '/credit-cards/negotiate-credit-card-interest/',
  },
  {
    from: '/learn/pending-transaction-credit-card/',
    status: 301,
    to: '/credit-cards/pending-transaction-credit-card/',
  },
  {
    from: '/learn/unsecured-vs-secured-line-of-credit/',
    status: 301,
    to: '/credit-cards/unsecured-vs-secured-line-of-credit/',
  },
  {
    from: '/learn/car-with-a-credit-card/',
    status: 301,
    to: '/credit-cards/car-with-a-credit-card/',
  },
  {
    from: '/learn/line-of-credit-vs-credit-card/',
    status: 301,
    to: '/credit-cards/line-of-credit-vs-credit-card/',
  },
  {
    from: '/learn/leasing-a-car-build-credit/',
    status: 301,
    to: '/credit-building/leasing-a-car-build-credit/',
  },
  {
    from: '/learn/credit-worthiness/',
    status: 301,
    to: '/credit-building/credit-worthiness/',
  },
  {
    from: '/learn/dispute-credit-report/',
    status: 301,
    to: '/credit-building/dispute-credit-report/',
  },
  {
    from: '/learn/build-credit-in-canada/',
    status: 301,
    to: '/credit-building/build-credit-in-canada/',
  },
  {
    from: '/learn/derogatory-mark-credit-report/',
    status: 301,
    to: '/credit-building/derogatory-mark-credit-report/',
  },
  {
    from: '/learn/credit-reference/',
    status: 301,
    to: '/credit-building/credit-reference/',
  },
  {
    from: '/learn/accept-new-credit-card/',
    status: 301,
    to: '/credit-cards/accept-new-credit-card/',
  },
  {
    from: '/learn/fake-credit-report/',
    status: 301,
    to: '/credit-building/fake-credit-report/',
  },
  {
    from: '/learn/hard-credit-check-affect-on-credit-score/',
    status: 301,
    to: '/credit-building/hard-credit-check-affect-on-credit-score/',
  },
  {
    from: '/learn/very-good-credit-score/',
    status: 301,
    to: '/credit-building/very-good-credit-score/',
  },
  {
    from: '/learn/fair-credit-score-range/',
    status: 301,
    to: '/credit-building/fair-credit-score-range/',
  },
  {
    from: '/learn/excellent-credit-score/',
    status: 301,
    to: '/credit-building/excellent-credit-score/',
  },
  {
    from: '/learn/how-to-pull-a-tenant-credit-report/',
    status: 301,
    to: '/credit-building/how-to-pull-a-tenant-credit-report/',
  },
  {
    from: '/learn/first-credit-card-approval/',
    status: 301,
    to: '/credit-cards/first-credit-card-approval/',
  },
  {
    from: '/learn/remove-inquiries-from-credit-report/',
    status: 301,
    to: '/credit-building/remove-inquiries-from-credit-report/',
  },
  {
    from: '/learn/credit-card-refunds-work-in-canada/',
    status: 301,
    to: '/credit-cards/credit-card-refunds-work-in-canada/',
  },
  {
    from: '/learn/how-long-does-it-take-to-get-credit-card/',
    status: 301,
    to: '/credit-cards/how-long-does-it-take-to-get-credit-card/',
  },
  {
    from: '/learn/how-is-credit-score-calculated/',
    status: 301,
    to: '/credit-building/how-is-credit-score-calculated/',
  },
  {
    from: '/learn/repair-bad-credit/',
    status: 301,
    to: '/credit-building/repair-bad-credit/',
  },
  {
    from: '/learn/do-internet-bills-affect-your-credit-score/',
    status: 301,
    to: '/credit-building/do-internet-bills-affect-your-credit-score/',
  },
  {
    from: '/learn/credit-card-protection-insurance/',
    status: 301,
    to: '/credit-cards/credit-card-protection-insurance/',
  },
  {
    from: '/learn/utilities-impact-on-credit-score/',
    status: 301,
    to: '/credit-building/utilities-impact-on-credit-score/',
  },
  {
    from: '/learn/withdrawing-cash-from-credit-card/',
    status: 301,
    to: '/credit-building/withdrawing-cash-from-credit-card/',
  },
  {
    from: '/learn/credit-score-for-car/',
    status: 301,
    to: '/credit-building/credit-score-for-car/',
  },
  {
    from: '/learn/average-canadian-credit-score/',
    status: 301,
    to: '/credit-building/average-canadian-credit-score/',
  },
  {
    from: '/learn/guaranteed-approval-credit-cards/',
    status: 301,
    to: '/credit-cards/best-instant-approval-credit-cards/ ',
  },
  {
    from: '/credit-cards/guaranteed-approval-credit-cards/',
    status: 301,
    to: '/credit-cards/best-instant-approval-credit-cards/ ',
  },
  {
    from: '/learn/line-of-credit-interest-rates-in-canada/',
    status: 301,
    to: '/learn/what-is-a-line-of-credit/',
  },
  {
    from: '/learn/free-trial-without-credit-card/',
    status: 301,
    to: '/credit-cards/free-trial-without-credit-card/',
  },
  {
    from: '/learn/prepaid-visa-vs-prepaid-mastercard/',
    status: 301,
    to: '/credit-cards/prepaid-visa-vs-prepaid-mastercard/',
  },
  {
    from: '/learn/how-long-credit-card-payment-take/',
    status: 301,
    to: '/credit-cards/how-long-credit-card-payment-take/',
  },
  {
    from: '/learn/canada-pro-deposit/',
    status: 301,
    to: '/personal-finance/canada-pro-deposit/',
  },
  {
    from: '/learn/cost-to-build-house-in-toronto/',
    status: 301,
    to: '/personal-finance/cost-to-build-house-in-toronto/',
  },
  {
    from: '/learn/gst-hst-credit/',
    status: 301,
    to: '/personal-finance/gst-hst-credit/',
  },
  {
    from: '/learn/notice-of-assessment/',
    status: 301,
    to: '/personal-finance/notice-of-assessment/',
  },
  {
    from: '/learn/provincial-tax-rates/',
    status: 301,
    to: '/personal-finance/provincial-tax-rates/',
  },
  {
    from: '/learn/resp-for-college-savings/',
    status: 301,
    to: '/personal-finance/resp-for-college-savings/',
  },
  {
    from: '/learn/rrsp-matching-in-canada/',
    status: 301,
    to: '/personal-finance/rrsp-matching-in-canada/',
  },
  {
    from: '/learn/rrsp-withdralws/',
    status: 301,
    to: '/personal-finance/rrsp-withdralws/',
  },
  {
    from: '/learn/tuition-tax-credit/',
    status: 301,
    to: '/personal-finance/tuition-tax-credit/',
  },
  {
    from: '/learn/zip-code-canada/',
    status: 301,
    to: '/credit-cards/zip-code-canada/',
  },
  {
    from: '/learn/when-to-pay-credit-card-bill/',
    status: 301,
    to: '/credit-cards/when-to-pay-credit-card-bill/',
  },
  {
    from: '/learn/transfer-money-from-credit-card/',
    status: 301,
    to: '/credit-cards/transfer-money-from-credit-card/',
  },
  {
    from: '/learn/secured-credit-card-deposit/',
    status: 301,
    to: '/credit-cards/secured-credit-card-deposit/',
  },
  {
    from: '/learn/overpay-credit-card-bill/',
    status: 301,
    to: '/credit-cards/overpay-credit-card-bill/',
  },
  {
    from: '/learn/minimum-payment-calculation/',
    status: 301,
    to: '/credit-cards/minimum-payment-calculation/',
  },
  {
    from: '/learn/is-it-hard-to-get-first-credit-card/',
    status: 301,
    to: '/credit-cards/is-it-hard-to-get-first-credit-card/',
  },
  {
    from: '/learn/first-credit-card-steps/',
    status: 301,
    to: '/credit-cards/first-credit-card-steps/',
  },
  {
    from: '/learn/credit-card-without-job/',
    status: 301,
    to: '/credit-cards/credit-card-without-job/',
  },
  {
    from: '/learn/age-for-credit-card-canada/',
    status: 301,
    to: '/credit-cards/age-for-credit-card-canada/',
  },
  {
    from: '/learn/quickly-increase-credit-score/',
    status: 301,
    to: '/credit-building/quickly-increase-credit-score/',
  },
  {
    from: '/learn/mortgage-vs-heloc-vs-home-equity-loan/',
    status: 301,
    to: '/credit-building/mortgage-vs-heloc-vs-home-equity-loan/',
  },
  {
    from: '/learn/equifax-vs-transunion/',
    status: 301,
    to: '/credit-building/equifax-vs-transunion/',
  },
  {
    from: '/learn/does-paybright-affect-credit-score/',
    status: 301,
    to: '/credit-building/does-paybright-affect-credit-score/',
  },
  {
    from: '/learn/collections-credit-report/',
    status: 301,
    to: '/credit-building/collections-credit-report/',
  },
  {
    from: '/learn/closing-credit-card/',
    status: 301,
    to: '/credit-building/closing-credit-card/',
  },
  {
    from: '/learn/bankruptcy-credit-report/',
    status: 301,
    to: '/credit-building/bankruptcy-credit-report/',
  },
  {
    from: '/learn/credit-unions-vs-banks/',
    status: 301,
    to: '/banking/credit-unions-vs-banks/',
  },
  {
    from: '/learn/student-bank-accounts-canada/',
    status: 301,
    to: '/banking/student-bank-accounts-canada/',
  },
  {
    from: '/learn/hard-credit-check-vs-soft-credit-check/',
    status: 301,
    to: '/credit-building/hard-credit-check-vs-soft-credit-check/',
  },
  {
    from: '/learn/why-did-my-credit-score-drop/',
    status: 301,
    to: '/credit-building/why-did-my-credit-score-drop/',
  },
  {
    from: '/learn/rapid-rescore/',
    status: 301,
    to: '/credit-building/rapid-rescore/',
  },
  {
    from: '/learn/deferred-payments/',
    status: 301,
    to: '/credit-building/deferred-payments/',
  },
  {
    from: '/learn/dental-health-benefit/',
    status: 301,
    to: '/personal-finance/dental-health-benefit/',
  },
  {
    from: '/learn/online-banks-in-canada/',
    status: 301,
    to: '/banking/online-banks-in-canada/',
  },
  {
    from: '/learn/automate-finances/',
    status: 301,
    to: '/personal-finance/automate-finances/',
  },
  {
    from: '/learn/foreign-transaction-fees/',
    status: 301,
    to: '/credit-cards/best-no-foreign-transaction-fee-credit-card/',
  },
  {
    from: '/credit-cards/foreign-transaction-fees/',
    status: 301,
    to: '/credit-cards/best-no-foreign-transaction-fee-credit-cards/',
  },
  {
    from: '/learn/what-is-credit-card-cash-back/',
    status: 301,
    to: '/learn/what-is-cash-back-on-a-credit-card/',
  },
  {
    from: '/learn/prepaid-card-while-travelling/',
    status: 301,
    to: '/learn/best-prepaid-credit-card-for-travel/',
  },
  {
    from: '/learn/paycheque-to-paycheque/',
    status: 301,
    to: '/learn/why-canadians-live-paycheque-to-paycheque/',
  },
  {
    from: '/learn/what-is-buy-now-pay-later-for-online-shopping/',
    status: 301,
    to: '/learn/what-is-buy-now-pay-later/',
  },
  {
    from: '/learn/payday-loans/',
    status: 301,
    to: '/learn/what-are-payday-loans/',
  },
  {
    from: '/learn/cash-advance-line-of-credit/',
    status: 301,
    to: '/learn/what-is-a-cash-advance-on-a-credit-card ',
  },
  {
    from: '/learn/first-home-savings-account/',
    status: 301,
    to: '/personal-finance/first-home-savings-account/',
  },
  {
    from: '/learn/no-credit-car-loans/',
    status: 301,
    to: '/credit-building/no-credit-car-loans/',
  },
  {
    from: '/learn/credit-freeze-canada/',
    status: 301,
    to: '/credit-building/credit-freeze-canada/',
  },
  {
    from: '/learn/bills-with-credit-card/',
    status: 301,
    to: '/credit-cards/bills-with-credit-card/',
  },
  {
    from: '/learn/paying-rent-with-credit-card/',
    status: 301,
    to: '/credit-cards/paying-rent-with-credit-card/',
  },
  {
    from: '/learn/rebuild-credit/',
    status: 301,
    to: '/credit-building/rebuild-credit/',
  },
  {
    from: '/learn/renting-with-bad-credit/',
    status: 301,
    to: '/credit-building/renting-with-bad-credit/',
  },
  {
    from: '/learn/credit-score-apps/',
    status: 301,
    to: '/learn/best-apps-to-check-credit-score-for-free',
  },
  {
    from: '/learn/what-is-a-virtual-credit-card-and-how-do-they-work/',
    status: 301,
    to: '/learn/what-is-a-virtual-credit-card/',
  },
  {
    from: '/learn/how-are-capital-gains-taxed-in-canada/',
    status: 301,
    to: '/learn/what-is-capital-gains-tax-in-canada/',
  },
  {
    from: '/learn/good-credit-score-canada/',
    status: 301,
    to: '/learn/what-is-a-good-credit-score/',
  },
  {
    from: '/save/pros-and-cons-hisa/',
    status: 301,
    to: '/save/best-high-interest-savings-accounts/',
  },
  {
    from: '/learn/average-savings-of-canadians-by-age/',
    status: 301,
    to: '/learn/what-is-the-average-salary-in-canada/',
  },
  {
    from: '/personal-finance/gst-hst-credit/',
    status: 301,
    to: '/personal-finance/gst-hst-credit-payment-dates/',
  },
  {
    from: '/fr/learn/what-is-a-virtual-credit-card-and-how-do-they-work/',
    status: 301,
    to: '/fr/learn/what-is-a-virtual-credit-card/',
  },
  {
    from: '/fr/learn/good-credit-score-canada/',
    status: 301,
    to: '/fr/learn/what-is-a-good-credit-score/',
  },
  {
    from: '/fr/personal-finance/gst-hst-credit/',
    status: 301,
    to: '/fr/gst-hst-credit-payment-dates/',
  },
  {
    from: '/credit-building/credit-score-apps/',
    status: 301,
    to: '/credit-building/best-apps-to-check-credit-score-for-free',
  },
  {
    from: '/fr/learn/credit-score-apps/',
    status: 301,
    to: '/fr/learn/best-apps-to-check-credit-score-for-free',
  },
  {
    from: '/fr/credit-building/credit-score-apps/',
    status: 301,
    to: '/fr/credit-building/best-apps-to-check-credit-score-for-free',
  },
  {
    from: '/fr/learn/predatory-lending/',
    status: 301,
    to: '/fr/personal-finance/predatory-lending/',
  },
  {
    from: '/fr/learn/remove-negative-things-credit-report/',
    status: 301,
    to: '/fr/credit-building/remove-negative-things-credit-report/',
  },
  {
    from: '/fr/learn/remove-inquiries-from-credit-report/',
    status: 301,
    to: '/fr/credit-building/remove-inquiries-from-credit-report/',
  },
  {
    from: '/fr/learn/how-to-build-credit-from-scratch/',
    status: 301,
    to: '/fr/credit-building/how-to-build-credit-from-scratch/',
  },
  {
    from: '/fr/learn/repair-bad-credit/',
    status: 301,
    to: '/fr/credit-building/repair-bad-credit/',
  },
  {
    from: '/fr/learn/hard-credit-check-affect-on-credit-score/',
    status: 301,
    to: '/fr/credit-building/hard-credit-check-affect-on-credit-score/',
  },
  {
    from: '/fr/learn/soft-credit-check-affect-credit-score/',
    status: 301,
    to: '/fr/credit-building/soft-credit-check-affect-credit-score/',
  },
  {
    from: '/fr/learn/credit-card-annual-fees/',
    status: 301,
    to: '/fr/credit-cards/credit-card-annual-fees/',
  },
  {
    from: '/fr/learn/credit-report-authorization/',
    status: 301,
    to: '/fr/credit-building/credit-report-authorization/',
  },
  {
    from: '/fr/learn/how-to-understand-your-credit-report/',
    status: 301,
    to: '/fr/credit-building/how-to-understand-your-credit-report/',
  },
  {
    from: '/fr/learn/how-is-credit-score-calculated/',
    status: 301,
    to: '/fr/credit-building/how-is-credit-score-calculated/',
  },
  {
    from: '/fr/learn/gic-early-withdrawl/',
    status: 301,
    to: '/fr/save/gic-early-withdrawl/',
  },
  {
    from: '/fr/learn/non-redeemable-gic/',
    status: 301,
    to: '/fr/save/non-redeemable-gic/',
  },
  {
    from: '/fr/learn/how-does-a-gic-work/',
    status: 301,
    to: '/fr/save/how-does-a-gic-work/',
  },
  {
    from: '/fr/learn/credit-card-protection-insurance/',
    status: 301,
    to: '/fr/credit-cards/credit-card-protection-insurance/',
  },
  {
    from: '/fr/learn/bank-account-with-bad-credit/',
    status: 301,
    to: '/fr/banking/bank-account-with-bad-credit/',
  },
  {
    from: '/fr/learn/secured-credit-card-ontario/',
    status: 301,
    to: '/fr/credit-cards/secured-credit-card-ontario/',
  },
  {
    from: '/fr/learn/secured-credit-card-manitoba/',
    status: 301,
    to: '/fr/credit-cards/secured-credit-card-manitoba/',
  },
  {
    from: '/fr/learn/secured-credit-card-british-columbia/',
    status: 301,
    to: '/fr/credit-cards/secured-credit-card-british-columbia/',
  },
  {
    from: '/fr/learn/secured-credit-card-alberta/',
    status: 301,
    to: '/fr/credit-cards/secured-credit-card-alberta/',
  },
  {
    from: '/fr/learn/when-credit-card-companies-report/',
    status: 301,
    to: '/fr/credit-building/when-credit-card-companies-report/',
  },
  {
    from: '/fr/learn/closed-accounts-on-credit-report/',
    status: 301,
    to: '/fr/credit-building/closed-accounts-on-credit-report/',
  },
  {
    from: '/fr/learn/excellent-credit-score/',
    status: 301,
    to: '/fr/credit-building/excellent-credit-score/',
  },
  {
    from: '/fr/learn/loan-with-bad-credit/',
    status: 301,
    to: '/fr/credit-building/loan-with-bad-credit/',
  },
  {
    from: '/fr/learn/poor-credit-score/',
    status: 301,
    to: '/fr/credit-building/poor-credit-score/',
  },
  {
    from: '/fr/learn/very-good-credit-score/',
    status: 301,
    to: '/fr/credit-building/very-good-credit-score/',
  },
  {
    from: '/fr/learn/fair-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/fair-credit-score-range/',
  },
  {
    from: '/fr/learn/good-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/good-credit-score-range/',
  },
  {
    from: '/fr/learn/790-credit-score/',
    status: 301,
    to: '/fr/credit-building/790-credit-score/',
  },
  {
    from: '/fr/learn/760-credit-score/',
    status: 301,
    to: '/fr/credit-building/760-credit-score/',
  },
  {
    from: '/fr/learn/740-credit-score/',
    status: 301,
    to: '/fr/credit-building/740-credit-score/',
  },
  {
    from: '/fr/learn/734-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/734-credit-score-range/',
  },
  {
    from: '/fr/learn/724-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/724-credit-score-range/',
  },
  {
    from: '/fr/learn/720-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/720-credit-score-range/',
  },
  {
    from: '/fr/learn/704-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/704-credit-score-range/',
  },
  {
    from: '/fr/learn/700-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/700-credit-score-range/',
  },
  {
    from: '/fr/learn/600-credit-score-range/',
    status: 301,
    to: '/fr/credit-building/600-credit-score-range/',
  },
  {
    from: '/fr/learn/first-home-savings-account/',
    status: 301,
    to: '/fr/personal-finance/first-home-savings-account/',
  },
  {
    from: '/fr/learn/credit-report-for-landlord/',
    status: 301,
    to: '/fr/credit-building/credit-report-for-landlord/',
  },
  {
    from: '/fr/learn/how-to-pull-a-tenant-credit-report/',
    status: 301,
    to: '/fr/credit-building/how-to-pull-a-tenant-credit-report/',
  },
  {
    from: '/fr/learn/rapid-rescore/',
    status: 301,
    to: '/fr/credit-building/rapid-rescore/',
  },
  {
    from: '/fr/learn/credit-reference/',
    status: 301,
    to: '/fr/credit-building/credit-reference/',
  },
  {
    from: '/fr/learn/credit-card-refunds-work-in-canada/',
    status: 301,
    to: '/fr/credit-cards/credit-card-refunds-work-in-canada/',
  },
  {
    from: '/fr/learn/bills-with-credit-card/',
    status: 301,
    to: '/fr/credit-cards/bills-with-credit-card/',
  },
  {
    from: '/fr/learn/fake-credit-report/',
    status: 301,
    to: '/fr/credit-building/fake-credit-report/',
  },
  {
    from: '/fr/learn/revolving-credit/',
    status: 301,
    to: '/fr/credit-building/revolving-credit/',
  },
  {
    from: '/fr/learn/how-long-does-it-take-to-get-credit-card/',
    status: 301,
    to: '/fr/credit-cards/how-long-does-it-take-to-get-credit-card/',
  },
  {
    from: '/fr/learn/dispute-credit-report/',
    status: 301,
    to: '/fr/credit-building/dispute-credit-report/',
  },
  {
    from: '/fr/learn/build-credit-in-canada/',
    status: 301,
    to: '/fr/credit-building/build-credit-in-canada/',
  },
  {
    from: '/fr/learn/derogatory-mark-credit-report/',
    status: 301,
    to: '/fr/credit-building/derogatory-mark-credit-report/',
  },
  {
    from: '/fr/learn/check-credit-score/',
    status: 301,
    to: '/fr/credit-building/check-credit-score/',
  },
  {
    from: '/fr/learn/credit-score-ranges/',
    status: 301,
    to: '/fr/credit-building/credit-score-ranges/',
  },
  {
    from: '/fr/learn/why-did-my-credit-score-drop/',
    status: 301,
    to: '/fr/credit-building/why-did-my-credit-score-drop/',
  },
  {
    from: '/fr/learn/transfer-money-between-bank/',
    status: 301,
    to: '/fr/banking/transfer-money-between-bank/',
  },
  {
    from: '/fr/learn/credit-worthiness/',
    status: 301,
    to: '/fr/credit-building/credit-worthiness/',
  },
  {
    from: '/fr/learn/withdrawing-cash-from-credit-card/',
    status: 301,
    to: '/fr/credit-building/withdrawing-cash-from-credit-card/',
  },
  {
    from: '/fr/learn/guaranteed-approval-credit-cards/',
    status: 301,
    to: '/fr/credit-cards/best-instant-approval-credit-cards/ ',
  },
  {
    from: '/fr/credit-cards/guaranteed-approval-credit-cards/',
    status: 301,
    to: '/fr/credit-cards/best-instant-approval-credit-cards/ ',
  },
  {
    from: '/fr/credit-cards/foreign-transaction-fees/',
    status: 301,
    to: '/fr/credit-cards/best-no-foreign-transaction-fee-credit-card/',
  },
  {
    from: '/fr/learn/what-is-credit-card-cash-back/',
    status: 301,
    to: '/fr/learn/what-is-cash-back-on-a-credit-card/',
  },
  {
    from: '/fr/learn/prepaid-card-while-travelling/',
    status: 301,
    to: '/fr/learn/best-prepaid-credit-card-for-travel/',
  },
  {
    from: '/fr/learn/paycheque-to-paycheque/',
    status: 301,
    to: '/fr/learn/why-canadians-live-paycheque-to-paycheque/',
  },
  {
    from: '/fr/learn/what-is-buy-now-pay-later-for-online-shopping/',
    status: 301,
    to: '/fr/learn/what-is-buy-now-pay-later/',
  },
  {
    from: '/fr/learn/payday-loans/',
    status: 301,
    to: '/fr/learn/what-are-payday-loans/',
  },

  {
    from: '/fr/learn/utilities-impact-on-credit-score/',
    status: 301,
    to: '/fr/credit-building/utilities-impact-on-credit-score/',
  },
  {
    from: '/fr/learn/deferred-payments/',
    status: 301,
    to: '/fr/credit-building/deferred-payments/',
  },
  {
    from: '/fr/learn/accept-new-credit-card/',
    status: 301,
    to: '/fr/credit-cards/accept-new-credit-card/',
  },
  {
    from: '/fr/learn/refunds-affect-credit-score/',
    status: 301,
    to: '/fr/credit-building/refunds-affect-credit-score/',
  },
  {
    from: '/fr/learn/no-credit-car-loans/',
    status: 301,
    to: '/fr/credit-building/no-credit-car-loans/',
  },
  {
    from: '/fr/learn/free-trial-without-credit-card/',
    status: 301,
    to: '/fr/credit-cards/free-trial-without-credit-card/',
  },
  {
    from: '/fr/learn/find-billing-address/',
    status: 301,
    to: '/fr/credit-cards/find-billing-address/',
  },
  {
    from: '/fr/learn/change-billing-address-on-credit-card/',
    status: 301,
    to: '/fr/credit-cards/change-billing-address-on-credit-card/',
  },
  {
    from: '/fr/learn/mortgage-with-bad-credit/',
    status: 301,
    to: '/fr/credit-building/mortgage-with-bad-credit/',
  },
  {
    from: '/fr/learn/line-of-credit-vs-credit-card/',
    status: 301,
    to: '/fr/credit-cards/line-of-credit-vs-credit-card/',
  },
  {
    from: '/fr/learn/car-with-a-credit-card/',
    status: 301,
    to: '/fr/credit-cards/car-with-a-credit-card/',
  },
  {
    from: '/fr/learn/unsecured-vs-secured-line-of-credit/',
    status: 301,
    to: '/fr/credit-cards/unsecured-vs-secured-line-of-credit/',
  },
  {
    from: '/fr/learn/pending-transaction-credit-card/',
    status: 301,
    to: '/fr/credit-cards/pending-transaction-credit-card/',
  },
  {
    from: '/fr/learn/negotiate-credit-card-interest/',
    status: 301,
    to: '/fr/credit-cards/negotiate-credit-card-interest/',
  },
  {
    from: '/fr/learn/credit-card-declined/',
    status: 301,
    to: '/fr/credit-cards/credit-card-declined/',
  },
  {
    from: '/fr/learn/going-over-credit-limit/',
    status: 301,
    to: '/fr/credit-cards/going-over-credit-limit/',
  },
  {
    from: '/fr/learn/balance-transfer-credit-card/',
    status: 301,
    to: '/fr/credit-cards/balance-transfer-credit-card/',
  },
  {
    from: '/fr/learn/costo-payment-methods/',
    status: 301,
    to: '/fr/credit-cards/costo-payment-methods/',
  },
  {
    from: '/fr/learn/credit-card-interest/',
    status: 301,
    to: '/fr/credit-cards/credit-card-interest/',
  },
  {
    from: '/fr/learn/negative-balance-on-credit-card/',
    status: 301,
    to: '/fr/credit-cards/negative-balance-on-credit-card/',
  },
  {
    from: '/fr/learn/leasing-a-car-build-credit/',
    status: 301,
    to: '/fr/credit-building/leasing-a-car-build-credit/',
  },
  {
    from: '/fr/learn/credit-score-for-car/',
    status: 301,
    to: '/fr/credit-building/credit-score-for-car/',
  },
  {
    from: '/fr/learn/do-internet-bills-affect-your-credit-score/',
    status: 301,
    to: '/fr/credit-building/do-internet-bills-affect-your-credit-score/',
  },
  {
    from: '/fr/learn/hard-credit-check-vs-soft-credit-check/',
    status: 301,
    to: '/fr/credit-building/hard-credit-check-vs-soft-credit-check/',
  },
  {
    from: '/fr/learn/credit-card-interest-monthly-or-yearly/',
    status: 301,
    to: '/fr/credit-cards/credit-card-interest-monthly-or-yearly/',
  },
  {
    from: '/fr/learn/charge-card-vs-credit-card/',
    status: 301,
    to: '/fr/credit-cards/charge-card-vs-credit-card/',
  },
  {
    from: '/fr/learn/fraud-affect-credit-score/',
    status: 301,
    to: '/fr/credit-building/fraud-affect-credit-score/',
  },
  {
    from: '/fr/learn/credit-disputes-affect-credit-score/',
    status: 301,
    to: '/fr/credit-building/credit-disputes-affect-credit-score/',
  },
  {
    from: '/fr/learn/changing-address-credit-score/',
    status: 301,
    to: '/fr/credit-building/changing-address-credit-score/',
  },
  {
    from: '/fr/learn/average-canadian-credit-score/',
    status: 301,
    to: '/fr/credit-building/average-canadian-credit-score/',
  },
  {
    from: '/fr/learn/credit-card-bill-credit-score/',
    status: 301,
    to: '/fr/credit-building/credit-card-bill-credit-score/',
  },
  {
    from: '/fr/learn/average-credit-card-debt/',
    status: 301,
    to: '/fr/credit-cards/average-credit-card-debt/',
  },
  {
    from: '/fr/learn/how-long-credit-card-payment-take/',
    status: 301,
    to: '/fr/credit-cards/how-long-credit-card-payment-take/',
  },
  {
    from: '/fr/learn/does-paybright-affect-credit-score/',
    status: 301,
    to: '/fr/credit-building/does-paybright-affect-credit-score/',
  },
  {
    from: '/fr/learn/prepaid-visa-vs-prepaid-mastercard/',
    status: 301,
    to: '/fr/credit-cards/prepaid-visa-vs-prepaid-mastercard/',
  },
  {
    from: '/fr/learn/refund-timeline/',
    status: 301,
    to: '/fr/credit-cards/refund-timeline/',
  },
  {
    from: '/fr/learn/credit-card-for-groceries/',
    status: 301,
    to: '/fr/credit-cards/credit-card-for-groceries/',
  },
  {
    from: '/fr/learn/secured-credit-card/',
    status: 301,
    to: '/fr/credit-cards/secured-credit-card/',
  },
  {
    from: '/fr/learn/grocery-store-credit-card/',
    status: 301,
    to: '/fr/credit-cards/grocery-store-credit-card/',
  },
  {
    from: '/fr/learn/does-credit-score-drop-when-you-check/',
    status: 301,
    to: '/fr/credit-building/does-credit-score-drop-when-you-check/',
  },
  {
    from: '/fr/learn/can-you-get-interest-on-a-chequing-account/',
    status: 301,
    to: '/fr/banking/can-you-get-interest-on-a-chequing-account/',
  },
  {
    from: '/fr/learn/renting-with-bad-credit/',
    status: 301,
    to: '/fr/credit-building/renting-with-bad-credit/',
  },
  {
    from: '/fr/learn/credit-monitoring/',
    status: 301,
    to: '/fr/credit-building/credit-monitoring/',
  },
  {
    from: '/fr/learn/closing-credit-card/',
    status: 301,
    to: '/fr/credit-building/closing-credit-card/',
  },
  {
    from: '/fr/learn/rebuild-credit/',
    status: 301,
    to: '/fr/credit-building/rebuild-credit/',
  },
  {
    from: '/fr/learn/minimum-payment-calculation/',
    status: 301,
    to: '/fr/credit-cards/minimum-payment-calculation/',
  },
  {
    from: '/fr/learn/credit-score-applying-for-credit-card/',
    status: 301,
    to: '/fr/credit-building/credit-score-applying-for-credit-card/',
  },
  {
    from: '/fr/learn/credit-card-balance-effect-on-credit-score/',
    status: 301,
    to: '/fr/credit-building/credit-card-balance-effect-on-credit-score/',
  },
  {
    from: '/fr/learn/quickly-increase-credit-score/',
    status: 301,
    to: '/fr/credit-building/quickly-increase-credit-score/',
  },
  {
    from: '/fr/learn/current-balance-statement-balance/',
    status: 301,
    to: '/fr/credit-cards/current-balance-statement-balance/',
  },
  {
    from: '/fr/learn/credit-unions-vs-banks/',
    status: 301,
    to: '/fr/banking/credit-unions-vs-banks/',
  },
  {
    from: '/fr/learn/tuition-tax-credit/',
    status: 301,
    to: '/fr/personal-finance/tuition-tax-credit/',
  },
  {
    from: '/fr/learn/rrsp-withdralws/',
    status: 301,
    to: '/fr/personal-finance/rrsp-withdralws/',
  },
  {
    from: '/fr/learn/transfer-money-from-credit-card/',
    status: 301,
    to: '/fr/credit-cards/transfer-money-from-credit-card/',
  },
  {
    from: '/fr/learn/student-bank-accounts-canada/',
    status: 301,
    to: '/fr/banking/student-bank-accounts-canada/',
  },
  {
    from: '/fr/learn/mortgage-vs-heloc-vs-home-equity-loan/',
    status: 301,
    to: '/fr/credit-building/mortgage-vs-heloc-vs-home-equity-loan/',
  },
  {
    from: '/fr/learn/provincial-tax-rates/',
    status: 301,
    to: '/fr/personal-finance/provincial-tax-rates/',
  },
  {
    from: '/fr/learn/notice-of-assessment/',
    status: 301,
    to: '/fr/personal-finance/notice-of-assessment/',
  },
  {
    from: '/fr/learn/equifax-vs-transunion/',
    status: 301,
    to: '/fr/credit-building/equifax-vs-transunion/',
  },
  {
    from: '/fr/learn/resp-for-college-savings/',
    status: 301,
    to: '/fr/personal-finance/resp-for-college-savings/',
  },
  {
    from: '/fr/learn/canada-pro-deposit/',
    status: 301,
    to: '/fr/personal-finance/canada-pro-deposit/',
  },
  {
    from: '/fr/learn/gst-hst-credit/',
    status: 301,
    to: '/fr/personal-finance/gst-hst-credit/',
  },
  {
    from: '/fr/learn/when-to-pay-credit-card-bill/',
    status: 301,
    to: '/fr/credit-cards/when-to-pay-credit-card-bill/',
  },
  {
    from: '/fr/learn/zip-code-canada/',
    status: 301,
    to: '/fr/credit-cards/zip-code-canada/',
  },
  {
    from: '/fr/learn/rrsp-matching-in-canada/',
    status: 301,
    to: '/fr/personal-finance/rrsp-matching-in-canada/',
  },
  {
    from: '/fr/learn/first-credit-card-approval/',
    status: 301,
    to: '/fr/credit-cards/first-credit-card-approval/',
  },
  {
    from: '/fr/learn/overpay-credit-card-bill/',
    status: 301,
    to: '/fr/credit-cards/overpay-credit-card-bill/',
  },
  {
    from: '/fr/learn/secured-credit-card-deposit/',
    status: 301,
    to: '/fr/credit-cards/secured-credit-card-deposit/',
  },
  {
    from: '/fr/learn/age-for-credit-card-canada/',
    status: 301,
    to: '/fr/credit-cards/age-for-credit-card-canada/',
  },
  {
    from: '/fr/learn/credit-card-without-job/',
    status: 301,
    to: '/fr/credit-cards/credit-card-without-job/',
  },
  {
    from: '/fr/learn/first-credit-card-steps/',
    status: 301,
    to: '/fr/credit-cards/first-credit-card-steps/',
  },
  {
    from: '/fr/learn/is-it-hard-to-get-first-credit-card/',
    status: 301,
    to: '/fr/credit-cards/is-it-hard-to-get-first-credit-card/',
  },
  {
    from: '/fr/learn/consumer-proposal/',
    status: 301,
    to: '/fr/credit-building/consumer-proposal/',
  },
  {
    from: '/fr/learn/collections-credit-report/',
    status: 301,
    to: '/fr/credit-building/collections-credit-report/',
  },
  {
    from: '/fr/learn/bankruptcy-credit-report/',
    status: 301,
    to: '/fr/credit-building/bankruptcy-credit-report/',
  },
  {
    from: '/learn/best-cash-back-credit-cards-in-canada/',
    status: 301,
    to: '/learn/best-cash-back-credit-cards/',
  },
  {
    from: '/credit-building/check-credit-score/',
    status: 301,
    to: '/credit-building/how-to-check-your-credit-score/',
  },
  {
    from: '/learn/credit-building-products-review/',
    status: 301,
    to: '/learn/best-credit-building-programs-canada/',
  },
  {
    from: '/learn/what-is-the-thirty-day-savings-rule/',
    status: 301,
    to: '/learn/what-is-the-50-30-20-rule/',
  },
  {
    from: '/learn/what-is-the-fifty-thirty-twenty-budgeting-rule/',
    status: 301,
    to: '/learn/what-is-the-50-30-20-rule/',
  },
  {
    from: '/credit-building/what-do-lenders-see-on-your-credit-report/',
    status: 301,
    to: '/learn/understanding-inquiries-on-your-credit-report/',
  },
  {
    from: '/save/how-does-a-gic-work/',
    status: 301,
    to: '/banking/how-does-a-gic-work/',
  },
  {
    from: '/learn/how-many-credit-cards-should-you-have/',
    status: 301,
    to: '/learn/how-many-credit-cards-should-i-have/',
  },
  {
    from: '/credit-building/revolving-credit/',
    status: 301,
    to: '/learn/revolving-credit/',
  },
  {
    from: '/credit-cards/current-balance-statement-balance/',
    status: 301,
    to: '/learn/what-is-a-bank-statement-everything-you-need-to-know/',
  },
  {
    from: '/credit-building/what-affects-credit-score/',
    status: 301,
    to: '/credit-building/what-affects-credit-scores/',
  },
  {
    from: '/learn/budgeting-in-college/',
    status: 301,
    to: '/learn/how-to-budget-as-a-student/',
  },
  {
    from: '/learn/how-to-improve-your-credit-score/',
    status: 301,
    to: '/learn/how-to-increase-your-credit-score/',
  },
  {
    from: '/learn/international-virtual-card/',
    status: 301,
    to: '/learn/best-travel-credit-cards-in-canada/',
  },
  {
    from: '/credit-cards/costo-payment-methods/',
    status: 301,
    to: '/credit-cards/what-credit-cards-does-costco-canada-accept/',
  },
  {
    from: '/credit-building/fastest-way-to-build-credit-in-canada/',
    status: 301,
    to: '/learn/fastest-way-to-build-credit-in-canada/',
  },
  {
    from: '/credit-building/consumer-proposal/',
    status: 301,
    to: '/learn/consumer-proposal/',
  },
  {
    from: '/credit-cards/average-credit-card-debt/',
    status: 301,
    to: '/credit-cards/the-state-of-average-credit-card-debt-canada/',
  },
  {
    from: '/learn/how-does-koho-make-money/',
    status: 301,
    to: '/learn/what-is-koho/',
  },
  {
    from: '/credit-building/can-paying-rent-build-credit/',
    status: 301,
    to: '/learn/rent-reporting-to-credit-bureau-canada/',
  },
  {
    from: '/credit-cards/how-to-redeem-cash-back/',
    status: 301,
    to: '/learn/what-is-cash-back-on-a-credit-card/',
  },
  {
    from: '/learn/best-canadian-credit-cards-in-the-market/',
    status: 301,
    to: '/credit-cards/best-instant-approval-credit-cards/',
  },
  {
    from: '/fr/learn/metal-cards-are-made-for-you/URL/',
    status: 301,
    to: '/fr/learn/metal-cards-are-made-for-you/',
  },
  {
    from: '/learn/safe/',
    status: 301,
    to: '/learn/what-is-koho/',
  },
  {
    from: '/credit-building/debt-management-plan/',
    status: 301,
    to: '/learn/credit-card-debt-and-high-interest-loans/',
  },
  {
    from: '/credit-building/refunds-affect-credit-score/',
    status: 301,
    to: '/credit-cards/credit-card-refunds-work-in-canada/',
  },
  {
    from: '/learn/tax-season-need-to-knows/',
    status: 301,
    to: '/learn/filing-a-tax-return-in-canada/',
  },
  {
    from: '/learn/should-new-canadians-get-debit-or-credit-card/',
    status: 301,
    to: '/learn/credit-cards-for-new-canadians/',
  },
  {
    from: '/fr/learn/can-paying-rent-build-credit/',
    status: 301,
    to: '/fr/credit-building/can-paying-rent-build-credit/',
  },
  {
    from: '/credit-building/changing-address-credit-score/',
    status: 301,
    to: '/credit-cards/change-billing-address-on-credit-card/',
  },
  {
    from: '/credit-building/fraud-affect-credit-score/',
    status: 301,
    to: '/credit-building/dispute-transaction-credit-score/',
  },
  {
    from: '/learn/tips-for-budgeting-with-cash-that-really-work/',
    status: 301,
    to: '/learn/how-to-budget-using-the-cash-envelope-system/',
  },
  {
    from: '/credit-cards/is-it-hard-to-get-first-credit-card/',
    status: 301,
    to: '/credit-cards/what-do-i-need-to-apply-for-a-credit-card/',
  },
  {
    from: '/learn/how-to-survive-a-recession/',
    status: 301,
    to: '/learn/how-to-prepare-for-a-recession/',
  },
  {
    from: '/learn/can-you-make-purchases-with-a-savings-account/',
    status: 301,
    to: '/learn/what-is-a-savings-account/',
  },
  {
    from: 'https://status.koho.ca/*',
    toPath: 'https://www.koho.ca/status/:splat',
    isPermanent: true,
    force: true,
    status: 301,
  },
  {
    from: '/overdraft-protection-cover/*',
    to: '/cover/:splat/',
    status: 301,
    force: true,
    isPermanent: true,
  },
  {
    from: '/fr/overdraft-protection-cover/*',
    to: '/fr/cover/:splat/',
    status: 301,
    force: true,
    isPermanent: true,
  },
];

const algoliaReplicaSettings = [
  {
    indexName: 'blogArticles_v1_en_minutes_to_read_asc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['asc(minutesToRead)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_en_minutes_to_read_desc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['desc(minutesToRead)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_en_published_at_asc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['asc(dateTimePublished)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_en_published_at_desc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['desc(dateTimePublished)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_fr_minutes_to_read_asc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['asc(minutesToRead)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_fr_minutes_to_read_desc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['desc(minutesToRead)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_fr_published_at_asc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['asc(dateTimePublished)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
  {
    indexName: 'blogArticles_v1_fr_published_at_desc',
    settings: {
      hitsPerPage: 9,
      customRanking: ['desc(dateTimePublished)'],
      attributesForFaceting: ['filterOnly(path)'],
    },
  },
];

module.exports = {
  pageVariants,
  urlBlocklists,
  whitelistQueryParams,
  redirects,
  algoliaReplicaSettings,
};
